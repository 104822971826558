<template>
  <div class="wet-module-container">
    <img
      src="../../assets/img/vertical-dots.svg"
      class="vertical-img-bg"
      alt="dots-bg-img"
    />
    <Menu></Menu>
    <section class="__section">
      <h1>{{ data.title }}</h1>
      <p>{{ data.description }}</p>
      <form
        class="md needs-validation is-valid d-flex flex-column"
        @submit="send"
      >
        <div class="form-group wet-form">
          <label class="form-label">{{ data.fields.email.text }}</label>
          <input
            v-model="Form.email"
            :placeholder="data.fields.email.placeholder"
            type="email"
            class="form-control"
            required
          />
        </div>
        <button class="btn btn-success btn-lg mt-5" type="submit">
          {{ data.actionButtonText }}
        </button>
      </form>
    </section>
  </div>
</template>
<script>
import Menu from "@/components/layout/Menu.vue";
import { passwordRecovery } from "../../helpers/auth";
import store from "../../store";

export default {
  components: { Menu },
  beforeDestroy() {},

  created() {},

  data() {
    return {
      Loader: false,
      data: {
        title: "Indique su correo",
        description:
          "El correo ingresado servira para poder reestablecer su contraseña.",
        actionButtonText: "Aceptar",
        fields: {
          email: { text: "Email de contacto", placeholder: "Ingrese un email" },
        },
      },
      Form: {
        email: null,
      },
    };
  },

  mounted() {
    this.fetchData();
  },
  watch: {
    "$store.state.sections": function () {
      this.fetchData();
    },
  },
  methods: {
    fetchData() {
      if (store.state.sections.forgotPassword) {
        this.data = { ...store.state.sections.forgotPassword.components };
      }
    },
    send: async function (e) {
      e.preventDefault();
      const response = await passwordRecovery({
        ...this.Form,
        language: this.$store.getters.getLanguage,
      });
      if (response.status === 200) {
        this.$router.push("/");
      }
    },
  },
};
</script>
<style lang="scss">
.__section {
  h1 {
    margin: 0 0 12px !important;
  }
}

@media (min-width: 1200px) {
  .__section {
    margin: 0 !important;

    form.md {
      max-width: 756px;
    }

    p {
      font-size: 18px !important;
      margin: 0 0 24px;
    }
  }
  .wet-module-container {
    justify-content: center;
  }
}
</style>
