<template>
  <nav
    class="
      landing-nav
      navbar-expand-xl
      bg-white
      shadow
      position-fixed
      fixed-top
    "
  >
    <div class="__container">
      <img
        @click="$router.push('/')"
        src="@/assets/logo/logo.webp"
        class="__brand-logo"
        loading="lazy"
        alt="wetForest-brand-logo"
      />

      <button
        class="navbar-toggler"
        id="toggleNav"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarMenu"
        aria-controls="navbarMenu"
        aria-expanded="true"
        aria-label="Toggle navigation"
      >
        <img src="@/assets/icons/menu-bars.svg" alt="menu-toggle" />
      </button>
    </div>

    <div class="collapse navbar-collapse w-100" id="navbarMenu">
      <ul class="navbar-nav mx-auto w-100 justify-content-center mb-2 mb-lg-0">
        <li
          class="nav-item mx-2 d-block d-md-none"
          @click="closeNav"
          v-for="link in links"
          :key="link.id + 123"
        >
          <a class="nav-link" v-bind:href="link.href"> {{ link.text }}</a>
        </li>
        <li
          class="nav-item mx-2 d-none d-md-block"
          v-for="link in links"
          :key="link.id"
        >
          <a class="nav-link" v-bind:href="link.href"> {{ link.text }}</a>
        </li>
      </ul>
      <div class="options-container">
        <UserOptions></UserOptions>
      </div>
    </div>
  </nav>
</template>
<script>
import UserOptions from "../Navigation/UserOptions";
import store from "../../store";

export default {
  components: { UserOptions },
  beforeDestroy() {},

  created() {},

  data() {
    return {
      links: [
        { text: "Inicio", href: "#Home", id: 1 },
        { text: "Modelos y Programas", href: "#Programas", id: 2 },
        { text: "Equipo y Experiencia", href: "#Experiencia", id: 3 },
        { text: "Quienes Somos", href: "#Quienes-somos", id: 4 },
        { text: "Contáctanos", href: "#Contactanos", id: 5 },
      ],
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    closeNav() {
      const button = document.getElementById("toggleNav");
      button.click();
    },
    fetchData() {
      if (store.state.sections.menu) {
        this.links = store.state.sections.menu.components.links;
      }
    },
  },
  watch: {
    "$store.state.sections": function () {
      this.fetchData();
    },
  },
};
</script>
<style lang="scss">
@import "src/assets/scss/colors";

.landing-nav {
  //align-items: center;
  display: flex;
  min-height: 72px;
  justify-content: flex-end;
  width: 100%;
  top: 0;
  right: 0;
  position: sticky;
  padding: 0 20px;
  flex-wrap: wrap;

  .nav-link {
    height: 100%;
    width: 100%;
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    color: $wet-black;

    &:active {
      font-weight: bold;
      color: #23af4d;
    }

    &:hover {
      color: #23af4d !important;
    }

    &:hover::before {
      content: " ";
      height: 2px;
      width: 100%;
      position: absolute;
      bottom: 0;
      border-bottom: 5px solid #23af4d;
      transition: all ease 0.1s;
    }
  }

  .__container {
    align-items: center;
    display: flex;
    height: 72px;
    justify-content: flex-end;
    width: 100%;
  }

  .__options {
    display: flex;
    width: fit-content;
    margin: 10px 0;
  }

  .__brand-logo {
    aspect-ratio: 3;
    height: 44px;
    position: absolute;
    left: 20px;
  }

  p {
    margin: 0 20px 0 0;
  }

  .navbar-toggler {
    aspect-ratio: 1;
    height: 40px;
    padding: 6px;
    margin: 0;
  }

  .options-container {
    position: relative;
    display: block;
  }
}

@media (min-width: 1200px) {
  .landing-nav {
    .__options {
      margin: 0;
    }

    .__container {
      width: fit-content;
    }

    .collapse {
      width: fit-content;
      flex-grow: initial;
    }

    .options-container {
      position: absolute;
      right: 0;
    }

    .navbar-nav {
      height: 100%;
    }
  }
}
</style>
