import {SERVER} from "@/config";
import store from '../store';
import Vue from 'vue';

export async function login(data) {
  let response;
  store.commit('ActivaLoader', true);
  try {
    const raw = await fetch(
      `${SERVER}/Usuario/InicioSession`,
      {
        method: "POST",
        headers: {"Content-type": "application/json;charset=UTF-8"},
        body: JSON.stringify(data),
      }
    );

    response = await raw.json();

    if (response.status === 200) {
      localStorage.setItem('hasPermission', JSON.stringify(true))
      localStorage.setItem('userData', JSON.stringify(response.data[0]))
    }
    if (response.status === 203) {
      Vue.swal(
        "Oops!",
        response.message,
        "warning"
      );
    }
  } catch (error) {
    store.commit('ActivaLoader', false);
    Vue.swal(
      "oops!",
      "Ocurrio un error enviando los datos, intentalo en unos minutos...",
      "error"
    );
  }
  store.commit('ActivaLoader', false);
  return response;
}

export async function passwordRecovery(data) {

  let body = {email: data.email, language: data.language}
  let response;
  store.commit('ActivaLoader', true);

  try {
    const raw = await fetch(
      `${SERVER}/Usuario/recovery`,
      {
        method: "POST",
        headers: {"Content-type": "application/json;charset=UTF-8"},
        body: JSON.stringify(
          body),
      }
    );

    response = await raw.json();

    if (response.status === 200) {
      const modalData = store.getters.getModalsData.components.forgotPasswordModal;
      Vue.swal(
        modalData.title,
        modalData.text,
        "success"
      );
    }

    // if (response.status === 203) {
    //   Vue.swal(
    //     "Oops!",
    //     response.message,
    //     "warning"
    //   );
    // }
  } catch (error) {
    store.commit('ActivaLoader', false);
    Vue.swal(
      "oops!",
      error,
      "error"
    );
  }
  store.commit('ActivaLoader', false);
  return (response)
}

export async function passwordReset(data) {


  const URL = SERVER;
  let response;
  store.commit('ActivaLoader', true);

  try {
    const raw = await fetch(
      `${URL}/Usuario`,
      {
        method: "PUT",
        headers: {"Content-type": "application/json;charset=UTF-8"},
        body: JSON.stringify(data),
      }
    );
    response = await raw.json();
    if (response.status === 200) {
      console.log('reset modal contact', store.getters.getModalsData.components.resetPasswordModal)
      Vue.swal(
        store.getters.getModalsData.components.resetPasswordModal.title,
        store.getters.getModalsData.components.resetPasswordModal.text,
        "success"
      );
    }

  } catch (error) {
    store.commit('ActivaLoader', false);
    Vue.swal(
      "oops!",
      "Ocurrio un error enviando los datos, intentalo en unos minutos...",
      "error"
    );
  }
  store.commit('ActivaLoader', false);
  return (response)
}


export async function SendContactForm(form) {
  const URL = SERVER + '/Email';
  let response;
  store.commit('ActivaLoader', true);
  try {
    const raw = await fetch(
      `${URL}/Contacto`,
      {
        method: "POST",
        headers: {"Content-type": "application/json;charset=UTF-8"},
        body: JSON.stringify(form),
      }
    )
    response = await raw.json();
    if (response.status === 200) {
      Vue.swal(
        store.getters.getModalsData.components.contactModal.title,
        store.getters.getModalsData.components.contactModal.text,
        "success"
      );
    }
  } catch (err) {
    store.commit('ActivaLoader', false);
    Vue.swal(
      "oops!",
      "Ocurrio un error enviando los datos, intentalo en unos minutos...",
      "error"
    );
  }
  store.commit('ActivaLoader', false);
  return response;
}

export async function registerUser(data) {
  console.log(data);

  let response;
  let body = {
    rut: data.document,
    email: data.contactEmail,
    password: data.password,
    empresa: data.companyName,
    website: data.website,
    contacto: data.contactName,
    telefono: data.phone,
    anoInicio: data.operationsYear,
    pais: data.companyCountry,
    language: data.language
  }


  store.commit('ActivaLoader', true);
  try {
    const raw = await fetch(
      `${SERVER}/Usuario`,
      {
        method: "POST",
        headers: {"Content-type": "application/json;charset=UTF-8"},
        body: JSON.stringify(
          body),
      }
    );

    response = await raw.json();

    if (response.status === 200) {
      Vue.swal(
        store.getters.getModalsData.components.registerModal.title,
        store.getters.getModalsData.components.registerModal.text,
        "success"
      );
    }

  } catch (error) {
    store.commit('ActivaLoader', false);
    Vue.swal(
      "oops!",
      "Ocurrio un error enviando los datos, intentalo en unos minutos...",
      "error"
    );
  }
  store.commit('ActivaLoader', false);
  return response;
}
